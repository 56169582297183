import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Button,
  Paper,
  Tabs,
  Tab,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
  SvgIcon,
  Grid,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import { Check as CheckIcon, AccessTimeFilled as AccessTimeFilledIcon } from '@mui/icons-material'
import { get } from 'lodash-es'
import {
  Applicant,
  CreditApplication,
  DraftApplicantSchema,
  DraftCreditApplicationSchema,
  FullCreditApplicationSchema,
  useDevTools,
  SelectValueListItem,
  Merchant,
} from '@src/data/types'
import Logo from '@src/assets/loop.svg?react'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import {
  Constants,
  EBeneficiaryType,
  ECreditApplicationStatus,
  EFinancingProgram,
  ELoanPurpose,
  ELoanPurposeList,
  ERelationToApplicant,
} from '../../../../data/types/constants'
import { useAppSelector, useAppDispatch } from '../../../../data/store'
import {
  creditSelectors,
  validateCreditApplicationCanBeSubmittedForReview,
  creditEffects,
  creditActions,
} from '../../../../data/store/CreditApplication'
import { appActions, appSelectors } from '../../../../data/store/AppStore'
import { InputTextField, PageError, PageSpinner, SelectComponent, TabPanel } from '../../../../components'
import ApplicantParameters from './components/Applicant/applicantComponent'
import SaveDialog from './components/SaveDialog'
import TestCaseDialog from './ListTestCaseDialog'

import { reportErrorToConsole } from '../../../../services/error-logger'
import MerchantDialog from '../../../../components/SelectMerchantDialog'

interface Props {
  creditApplication: CreditApplication
  onSave: (data: Partial<CreditApplication>) => void
}

const CreditApplicationForm = ({ creditApplication, onSave }: Props) => {
  const SAVE_DIALOG = 'OpenSaveDialog'
  const PREQUAL_DIALOG = 'OpenPrequalDialog'
  const TEST_DIALOG = 'OpenTestDialog'
  const MERCHANT_DIALOG = 'OpenMerchantDialog'
  const [searchParams] = useSearchParams()
  const showTabIndex = searchParams.get('showTabIndex')
  const [hasCoapplicantChecked, setCoApplicantChecked] = React.useState<boolean>(!!creditApplication.coApplicant)
  const tabIndex = Number(showTabIndex) ?? 0
  const [currentOpenDialog, setCurrentOpenDialog] = useState<string>('')
  const [draftMod, setDraftMod] = React.useState(true)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { financingProgramId } = useParams()

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm<CreditApplication>({
    mode: 'onBlur', // déclenche les validations Après que l'usager ait quitté le champ
    defaultValues: creditApplication,
    resolver: draftMod ? yupResolver(DraftCreditApplicationSchema) : yupResolver(FullCreditApplicationSchema),
  })

  const dispatch = useAppDispatch()
  const dispatchEffect = useSideEffect() // to trigger api call

  const isSaving = useAppSelector(creditSelectors.isSavingCreditApplication)
  const error = useAppSelector(appSelectors.getBusinessError)

  const editDisabled = creditApplication?.editLocked === true
  const selectMerchant = useAppSelector(creditSelectors.getMerchant)

  //  Reset errors when component is mounted
  useEffect(() => {
    dispatch(appActions.resetErrors())
  }, [dispatch])

  const loanPurposeId = watch('loanPurposeId') as ELoanPurpose
  const beneficiaryTypeId = Number(watch('beneficiaryTypeId')) as EBeneficiaryType
  const isVeterinaryLoanPurpose = ELoanPurpose.Veterinary === loanPurposeId
  const isGoodAndServiceLoanPurpose = ELoanPurpose.GoodsAndServices === loanPurposeId
  const disableOtherName = beneficiaryTypeId !== EBeneficiaryType.Other

  const beneficiaryTypes = useMemo((): SelectValueListItem[] => {
    const beneficiaryTypesList: SelectValueListItem[] = []
    if (
      loanPurposeId &&
      (
        [
          ELoanPurpose.Aesthetics,
          ELoanPurpose.Medical,
          ELoanPurpose.Dentistry,
          ELoanPurpose.GoodsAndServices,
        ] as string[]
      ).includes(loanPurposeId, 0)
    ) {
      beneficiaryTypesList.push(
        {
          value: EBeneficiaryType.Applicant,
          label: t('enum.beneficiaryType.applicant'),
        },
        {
          value: EBeneficiaryType.Other,
          label: t('enum.beneficiaryType.other'),
        },
      )
      if (hasCoapplicantChecked) {
        beneficiaryTypesList.push({
          value: EBeneficiaryType.Coapplicant,
          label: t('enum.beneficiaryType.coApplicant'),
        })
      }
    } else {
      beneficiaryTypesList.push({
        value: EBeneficiaryType.Other,
        label: t('enum.beneficiaryType.other'),
      })
      if (isVeterinaryLoanPurpose) {
        setValue('beneficiaryTypeId', EBeneficiaryType.Other)
        setValue('otherBeneficiaryFirstName', '')
      }
    }
    return beneficiaryTypesList
  }, [t, loanPurposeId, hasCoapplicantChecked, isVeterinaryLoanPurpose, setValue])

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      const { location } = window
      const updateSearch = new URLSearchParams(location.search)
      updateSearch.set('showTabIndex', encodeURIComponent(newValue))
      const newUrl = `${location.pathname}?${updateSearch.toString()}`
      navigate(newUrl, { replace: true })
    },
    [navigate],
  )

  const closeDialogs = useCallback(() => {
    setCurrentOpenDialog('')
  }, [setCurrentOpenDialog])

  const handleToggleCoapplicant = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setValue('coApplicant', DraftApplicantSchema.getDefault() as Applicant)
        setValue('coApplicant.isPrimaryApplicant', false)
      } else {
        setValue('coApplicant', null)
      }
      setCoApplicantChecked(event.target.checked)
    },
    [setValue, setCoApplicantChecked],
  )

  const handleMerchantChanged = useCallback(
    (newMerchant: Merchant) => {
      dispatch(creditActions.setMerchant(newMerchant))
      setValue('merchantId', newMerchant.id)
      setValue('loanPurposeId', newMerchant.serviceCategory ?? ('' as ELoanPurpose))
      closeDialogs()
    },
    [closeDialogs, dispatch, setValue],
  )

  const submit = useCallback(() => {
    closeDialogs()
    const data = getValues()
    data.status = draftMod ? ECreditApplicationStatus.Draft : ECreditApplicationStatus.Active
    data.consentSoftHit = true
    data.consentHardHit = true
    onSave(data)
  }, [onSave, getValues, draftMod, closeDialogs])

  const onFormSubmitted = useCallback(
    (data: CreditApplication) => {
      if (!draftMod) {
        const globalErrors = validateCreditApplicationCanBeSubmittedForReview(data)
        if (globalErrors.length > 0) {
          dispatch(appActions.setBusinessErrors(globalErrors))
        } else {
          if (data.financingProgramId !== EFinancingProgram.Personal) {
            dispatchEffect(creditEffects.downloadCreditPdf(data)).catch(reportErrorToConsole)
          }
          setCurrentOpenDialog(SAVE_DIALOG)
        }
      } else {
        submit()
      }
    },
    [draftMod, submit, setCurrentOpenDialog, dispatchEffect, dispatch],
  )

  const onTestSelected = useCallback(
    (applicant: Applicant) => {
      const months = (applicant.currentAddress.months ?? 0) % 12
      applicant.currentAddress.years = ((applicant.currentAddress.months ?? 0) - months) / 12
      if (applicant.currentAddress.years === 0) {
        applicant.currentAddress.years = null
      }
      if (months === 0) {
        applicant.currentAddress.months = null
      } else {
        applicant.currentAddress.months = months
      }
      if (tabIndex === 0) {
        applicant.isPrimaryApplicant = true
        reset({ ...getValues(), applicant })
      } else {
        applicant.isPrimaryApplicant = false
        applicant.relationWithApplicant = ERelationToApplicant.CommonLaw
        reset({ ...getValues(), coApplicant: applicant })
      }
      closeDialogs()
    },
    [tabIndex, closeDialogs, reset, getValues],
  )

  const handlePrequalify = async () => {
    await trigger()
    const data = getValues()
    const globalErrors = validateCreditApplicationCanBeSubmittedForReview(data)
    if (globalErrors.length > 0) {
      dispatch(appActions.setBusinessErrors(globalErrors))
    } else {
      data.status = ECreditApplicationStatus.Active
      data.consentSoftHit = true
      data.consentHardHit = false
      onSave(data)
    }
    closeDialogs()
  }

  const financingProgramConfig = get(
    Constants.financingProgramConfigs,
    financingProgramId?.toLowerCase() ?? 'default',
    Constants.financingProgramConfigs.default,
  )

  return (
    <div style={{ height: '100%' }}>
      <SaveDialog onClose={closeDialogs} open={currentOpenDialog === SAVE_DIALOG} onSubmit={submit} />
      <Dialog onClose={closeDialogs} open={currentOpenDialog === PREQUAL_DIALOG} onSubmit={handlePrequalify}>
        <DialogContent>{t('common.prequalify')}?</DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeDialogs}>
            {t('common.cancel')}
          </Button>
          <Button variant="contained" onClick={handlePrequalify}>
            {t('common.prequalify')}
          </Button>
        </DialogActions>
      </Dialog>
      <PageSpinner isLoading={isSaving} withBackdrop />
      <TestCaseDialog
        onClose={closeDialogs}
        open={currentOpenDialog === TEST_DIALOG}
        financingProgramId={watch('financingProgramId')}
        onTestCaseSelected={onTestSelected}
      />
      <Paper>
        <PageError errors={error} />
        <form onSubmit={handleSubmit(onFormSubmitted, reportErrorToConsole)}>
          <Grid container mb={4}>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <div>
                {creditApplication.id && (
                  <>
                    <Chip
                      sx={{ marginRight: '1rem' }}
                      label={t('common.consentSoftHit')}
                      icon={
                        creditApplication?.consentSoftHit ? (
                          <CheckIcon color="success" />
                        ) : (
                          <AccessTimeFilledIcon color="warning" />
                        )
                      }
                    />
                    <Chip
                      sx={{ marginRight: '1rem' }}
                      label={t('common.consentHardHit')}
                      icon={
                        creditApplication?.consentHardHit ? (
                          <CheckIcon color="success" />
                        ) : (
                          <AccessTimeFilledIcon color="warning" />
                        )
                      }
                    />
                  </>
                )}
                {creditApplication.status === ECreditApplicationStatus.Draft && (
                  <Button
                    sx={{ marginRight: 2 }}
                    type="submit"
                    variant="contained"
                    value="draft"
                    color="secondary"
                    disabled={isSaving}
                    onClick={() => setDraftMod(true)}
                  >
                    {t('common.saveDraft')}
                  </Button>
                )}
                {financingProgramConfig.useSoftHit && (
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isSaving || editDisabled}
                    onClick={() => {
                      setDraftMod(false)
                      setCurrentOpenDialog(PREQUAL_DIALOG)
                    }}
                  >
                    {t('common.prequalify')}
                  </Button>
                )}
                <Button
                  sx={{ marginLeft: 2 }}
                  type="submit"
                  variant="contained"
                  color="warning"
                  value="finale"
                  disabled={isSaving || editDisabled}
                  onClick={() => setDraftMod(false)}
                >
                  {t('common.submitAndPrint')}
                </Button>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              {!creditApplication.id && (
                <InputTextField
                  InputProps={{
                    endAdornment: (
                      <IconButton aria-label="swap" onClick={() => setCurrentOpenDialog(MERCHANT_DIALOG)}>
                        <SvgIcon fontSize="large">
                          <Logo />
                        </SvgIcon>
                      </IconButton>
                    ),
                  }}
                  value={selectMerchant?.name ?? ''}
                  disabled
                  label={t('common.merchant')}
                  error={errors?.merchantId}
                />
              )}
            </Grid>

            {financingProgramConfig.requestedLoanAmountIsRequired && (
              <Grid item xs={12} md={3}>
                <InputTextField
                  InputProps={{
                    endAdornment: '$',
                  }}
                  disabled={editDisabled}
                  error={errors?.requestedLoanAmount}
                  label={t('editCreditApplication.amountRequested')}
                  {...register('requestedLoanAmount')}
                />
              </Grid>
            )}

            {creditApplication.financingProgramId === EFinancingProgram.Personal && (
              <>
                <Grid item xs={12} md={3}>
                  <SelectComponent
                    items={ELoanPurposeList.map((item) => ({
                      label: `enum.eLoanPurposes.${item}`,
                      value: item,
                    }))}
                    label={t('editCreditApplication.loanPurpose') as string}
                    {...register('loanPurposeId')}
                    error={errors?.loanPurposeId}
                    disabled={editDisabled}
                  />
                </Grid>
                {!isGoodAndServiceLoanPurpose && (
                  <Grid container spacing={2} margin={0}>
                    <Grid item xs={4} md={4}>
                      <SelectComponent
                        items={beneficiaryTypes}
                        label={t('editCreditApplication.beneficiary.beneficiary') as string}
                        disabled={isVeterinaryLoanPurpose || editDisabled}
                        {...register('beneficiaryTypeId')}
                      />
                    </Grid>
                    {!isVeterinaryLoanPurpose && (
                      <Grid item xs={4} md={4}>
                        <InputTextField
                          error={errors?.otherBeneficiaryFirstName}
                          label={t('editCreditApplication.beneficiary.otherBeneficiaryFirstName')}
                          disabled={disableOtherName || editDisabled}
                          {...register('otherBeneficiaryFirstName')}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={4} md={4}>
                      <InputTextField
                        error={errors?.otherBeneficiaryLastName}
                        label={
                          !isVeterinaryLoanPurpose
                            ? t('editCreditApplication.beneficiary.otherBeneficiaryLastName')
                            : t('editCreditApplication.beneficiary.animalName')
                        }
                        disabled={disableOtherName || editDisabled}
                        {...register('otherBeneficiaryLastName')}
                        inputProps={{ maxLength: 50 }}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab
                icon={errors.applicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('common.applicant')}
              />
              <Tab
                icon={errors.coApplicant ? <PriorityHighIcon color="error" /> : ''}
                iconPosition="end"
                label={t('common.coApplicant')}
              />
            </Tabs>
          </Box>
          <Box sx={{ flex: '1 1 auto' }} />
          <input type="hidden" value=" " {...register('id')} />
          <input type="hidden" value=" " {...register('merchantId')} />
          <TabPanel value={tabIndex} index={0}>
            {useDevTools && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={() => setCurrentOpenDialog(TEST_DIALOG)}
                >
                  Cas de test
                </Button>
              </Box>
            )}
            <ApplicantParameters
              register={register}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              trigger={trigger}
              errors={errors.applicant}
              name="applicant"
              formControl={control}
              editDisabled={editDisabled}
              financingProgramId={creditApplication.financingProgramId}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <FormControlLabel
              control={<Checkbox checked={hasCoapplicantChecked} onChange={handleToggleCoapplicant} />}
              label={t('common.coApplicant')}
            />
            {hasCoapplicantChecked && (
              <>
                {useDevTools && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', p: 2 }}>
                    <Button
                      type="button"
                      variant="contained"
                      color="secondary"
                      onClick={() => setCurrentOpenDialog(TEST_DIALOG)}
                    >
                      Cas de test
                    </Button>
                  </Box>
                )}
                <ApplicantParameters
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  trigger={trigger}
                  errors={errors?.coApplicant}
                  name="coApplicant"
                  formControl={control}
                  editDisabled={editDisabled}
                  financingProgramId={creditApplication.financingProgramId}
                />
              </>
            )}
          </TabPanel>
        </form>
      </Paper>
      <MerchantDialog
        open={currentOpenDialog === MERCHANT_DIALOG}
        title={t('worksheet.modifyMerchant')}
        label={t('taskManager.merchantName')}
        onConfirm={handleMerchantChanged}
        onCancel={closeDialogs}
        financingProgramId={creditApplication.financingProgramId}
        allowMultiple={false}
      />
    </div>
  )
}

export default CreditApplicationForm
